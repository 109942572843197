import Work1 from "../../assets/invaders.avif";
import Work2 from "../../assets/price_tracker.svg";
import Work3 from "../../assets/weather.webp";
import Work4 from "../../assets/face-recognition.jpg";

export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "3D Space Invaders",
    link: "https://github.com/Typher7",
  },
  {
    id: 2,
    image: Work2,
    title: "Price Tracker",
    category: "app",
    link: "https://github.com/Typher7/Price-Tracker",
  },
  {
    id: 3,
    image: Work3,
    title: "Weather",
    category: "app",
    link: "https://github.com/Typher7/weather-app-react",
  },

  {
    id: 4,
    image: Work4,
    title: "Facial Recognition",
    link: "https://github.com/Typher7/Facial_Recognition",
  },
];
